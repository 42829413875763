import { Injectable } from "@angular/core";
import { BehaviorSubject, from, Observable, of, Subscription, throwError } from "rxjs";
import { switchMap } from "rxjs/operators";
import { DateTime, TimeSpan } from "ews-js-api-browser";
import { WebsocketService } from "../api/websocket.service";
import { TranslateService } from "@ngx-translate/core";
import { LanguageService } from "../language-service/language.service";
import { ModalService, ModalType } from "../modal-service/modal.service";
import { DoorBellButton, ResourceObject, SettingsInterface } from "src/app/interfaces/interfaces";

@Injectable({
	providedIn: "root",
})
export class ResourceService {
	private currentDay = DateTime.Now.Today;
	public clock;
	public date;
	public resource: BehaviorSubject<ResourceObject> = new BehaviorSubject(undefined);
	public resourceSettings: BehaviorSubject<SettingsInterface> = new BehaviorSubject(undefined);
	public buttoneSettings: BehaviorSubject<DoorBellButton> = new BehaviorSubject(undefined);

	public avatar = new BehaviorSubject<any>("./assets/img/icon.png");

	constructor(
		private socket: WebsocketService,
		private translate: TranslateService,
		private language: LanguageService
	) {
		this.startClock();
	}

	startEventListener() {
		// return this.socket.eventsCallback.pipe(
		//   switchMap(eventsList => {
		//     return this.checkEvents(eventsList).pipe(
		//       switchMap(events => {
		//         this.events.next(events);
		//         const aMeeting = events.find(e => e.activeStatus === 2 || e.activeStatus === 1 || e.activeStatus === 0);
		//         this.activeEvent.next(aMeeting);
		//         return this.checkMembers().pipe();
		//       })
		//     );
		//   })
		// );
	}

	startResourceListener() {
		return this.socket.resourceCallback.pipe(
			switchMap((resource: ResourceObject) => {
				this.checkResource(resource);
				if (resource.connectorId != null) {
					this.socket.removeConnectorCallback.next(false);
				} else {
					this.socket.removeConnectorCallback.next(true);
				}
				return this.checkButtons(resource).pipe();
			})
		);
	}

	checkResource(resource: ResourceObject) {
		// this.language.setLanguage(resource.language);

		this.resourceSettings.next(resource.settings);

		this.resource.next(resource);
	}

	getMessageReaction() {
		return this.socket.getMessageReaction();
	}

	getMessageReplay() {
		return this.socket.getMessageReplay();
	}
	checkButtons(resource: ResourceObject) {
		if (
			resource.buttons == null ||
			resource.buttons == undefined ||
			resource.buttons.length == 0
		) {
			return of(false);
		} else {
			return of(true);
		}
	}

	startClock() {
		setInterval(() => {
			this.clock = new Date();
			this.date = new Date();
			//this.checkIfNextDay();
		}, 1000);
	}

	avatarBlobber(avatar) {
		const img = avatar;
		fetch(avatar).then((r) =>
			r.blob().then((b) => {
				const fileReader = new FileReader();
				fileReader.readAsDataURL(b);
				fileReader.onload = (ev) => {
					this.avatar.next(ev.target.result);
				};
			})
		);
	}
}