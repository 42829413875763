import { enableProdMode, importProvidersFrom, PLATFORM_ID } from "@angular/core";
import "@capgo/camera-preview";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { environment } from "./environments/environment";
import { bootstrapApplication } from "@angular/platform-browser";
import { AppComponent } from "./app/app.component";
import { provideRouter } from "@angular/router";
import { routes } from "src/app/app-routing.module";
import { HttpClient, provideHttpClient } from "@angular/common/http";
import { provideTranslateService, TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { IonicModule } from "@ionic/angular";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";


if (environment.production) {
	enableProdMode();
}

export function createTranslateLoader(http: HttpClient) {
	return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

bootstrapApplication(AppComponent, {
	providers: [
		provideRouter(routes),
		provideHttpClient(),
		provideTranslateService({
			loader: {
				provide: TranslateLoader,
				useFactory: createTranslateLoader,
				deps: [HttpClient],
			},
		}),
		importProvidersFrom(IonicModule.forRoot(), BrowserAnimationsModule),
	],
}).catch((err) => console.error(err));
