import { CommonModule } from "@angular/common";
import { Component, OnInit, Input, OnDestroy } from "@angular/core";
import { IonicModule, NavParams } from "@ionic/angular";
import { ModalController } from "@ionic/angular";
import { TranslateModule } from "@ngx-translate/core";

@Component({
	selector: "app-error-modal",
	templateUrl: "./error-modal.page.html",
	styleUrls: ["./error-modal.page.scss"],
	imports: [IonicModule, TranslateModule, CommonModule],
})
export class ErrorModalPage implements OnInit, OnDestroy {
	@Input() message: string;

	constructor(private modalService: ModalController, private navParams: NavParams) {}

	ngOnInit() {}

	close() {
		this.modalService.dismiss();
	}

	ngOnDestroy() {}
}
