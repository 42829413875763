import { Injectable } from '@angular/core';
import { Preferences } from '@capacitor/preferences';

@Injectable({
  providedIn: 'root',
})
export class PreferencesService {
  constructor() {}

  /**
   * Set a value in Preferences storage.
   * @param key The key to identify the value.
   * @param value The value to store (stringified if it's not a string).
   */
  async set(key: string, value: string): Promise<void> {
    await Preferences.set({
      key,
      value: value
    });
  }

  /**
   * Get a value from Preferences storage.
   * @param key The key to retrieve the value.
   * @returns The stored value, parsed if it was a JSON string.
   */
  async get(key: string): Promise<string | null> {
    const { value } = await Preferences.get({ key });
    try {
      return value ? JSON.parse(value) : null;
    } catch {
      return value
    }
  }

  /**
   * Remove a value from Preferences storage.
   * @param key The key to remove.
   */
  async remove(key: string): Promise<void> {
    await Preferences.remove({ key });
  }

  /**
   * Clear all Preferences storage.
   */
  async clear(): Promise<void> {
    await Preferences.clear();
  }

  /**
   * Check if a key exists in Preferences storage.
   * @param key The key to check.
   * @returns `true` if the key exists, otherwise `false`.
   */
  async hasKey(key: string): Promise<boolean> {
    const { keys } = await Preferences.keys();
    return keys.includes(key);
  }
}
