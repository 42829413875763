import { Component, OnInit, Input } from "@angular/core";
import { IonicModule } from "@ionic/angular";
import { ModalController } from "@ionic/angular";
import { TranslateModule } from "@ngx-translate/core";

@Component({
	selector: "app-navigate-modal",
	templateUrl: "./navigate-modal.component.html",
	styleUrls: ["./navigate-modal.component.scss"],
	imports: [IonicModule, TranslateModule],
})
export class NavigateModalComponent implements OnInit {
	@Input() message: string;
	constructor(private mdlCtrl: ModalController) {}

	ngOnInit() {}

	close(ev: boolean) {
		this.mdlCtrl.dismiss(ev);
	}
}
