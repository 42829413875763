<ion-content fullscreen>
  <ion-button fill="clear" color="dark" class="modal-btn" (click)="close()"><ion-icon
      name="close"></ion-icon></ion-button>
  <ion-grid style="height: 100%">

    <ion-row class="ion-justify-content-center ion-align-items-center" style="height: 100%">
      <ion-col size="6" class="ion-text-center">
        <ion-icon name="checkmark-circle-outline" color="success" style="font-size: 4.5em;"></ion-icon>
        <h2> {{ message | translate}} </h2>
        <ion-button class="ion-padding" expand="block" color="dark" (click)="close()">{{ 'BTN.OK' |
          translate}}</ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>