import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { PreferencesService } from '../preferences/preferences-service.service';

const LNG_KEY = 'SELECTED_LANGUAGE';
const PORTAL_LNG_KEY = 'SELECTED_PORTAL_LANGUAGE';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  langus;
  selected = new BehaviorSubject<any>(undefined);

  constructor(private translate: TranslateService, private storage: PreferencesService) {
    this.setInitLang();
  }


  async setInitLang() {
    const language = this.translate.getBrowserLang();
    this.translate.setDefaultLang(language);
    await this.getselected();
  }

  async getselected() {
    const val = await this.storage.get(LNG_KEY);
    if (val) {
      this.selected.next(val);
      this.translate.use(val);
    } else {
      this.selected.next(this.translate.getBrowserLang());
    }
  }

  getLanguage() {
    return [
      { text: 'English(UK)', value: 'en-GB', img: 'assets/flags/en.png' },
      { text: 'English(US)', value: 'en', img: 'assets/flags/en.png' },
      { text: 'Deutsch', value: 'de', img: 'assets/flags/de.png' }
    ];
  }

  async setLanguage(lng: string) {
    this.translate.use(lng);
    this.selected.next(lng);
    await this.storage.set(LNG_KEY, lng);
  }

  async setLanguageFromPortal(lng: string) {
    const portalLang = await this.getPortalLanguage();
    if (portalLang) {
      if (portalLang !== lng) {
        await this.setLanguage(lng);
      } else {
        await this.getselected();
      }
    } else {
      await this.getselected();
    }
    await this.storage.set(PORTAL_LNG_KEY, lng);
  }

  async getPortalLanguage() {
    return this.storage.get(PORTAL_LNG_KEY);
  }
}
