import { Component, OnInit } from "@angular/core";
import { IonicModule, NavParams } from "@ionic/angular";
import { TranslateModule } from "@ngx-translate/core";

@Component({
	selector: "app-no-licenses-modal",
	templateUrl: "./no-licenses-modal.component.html",
	styleUrls: ["./no-licenses-modal.component.scss"],
	imports: [IonicModule, TranslateModule],
})
export class NoLicensesModalComponent implements OnInit {
	public message: string;
	constructor(private navParams: NavParams) {}

	ngOnInit() {}
}
