import { take } from "rxjs/operators";
import { WebsocketService } from "./services/api/websocket.service";
import { switchMap, mergeMap } from "rxjs/operators";
import { Component, OnInit, Renderer2 } from "@angular/core";
import { Platform, LoadingController, IonicModule } from "@ionic/angular";
import { StatusBar } from "@capacitor/status-bar";
import { Router } from "@angular/router";
import { ApiService } from "./services/api/api.service";
import { TranslateService } from "@ngx-translate/core";
import { LanguageService } from "./services/language-service/language.service";
import { LicenseService } from "./services/api/license.service";
import { of, Subscription, timer } from "rxjs";
import { ModalService } from "./services/modal-service/modal.service";
import { ResourceService } from "./services/resources/resource.service";
import { AuthGuard } from "./guards/auth.guard";
import { SplashScreen } from "@capacitor/splash-screen";
import { App } from "@capacitor/app";
import { CommonModule } from "@angular/common";

@Component({
	selector: "app-root",
	templateUrl: "app.component.html",
	imports: [IonicModule, CommonModule],
})
export class AppComponent implements OnInit {
	appVersion = "2.0.0";
	private reconnectRunning = false;
	public licenseSubscription: Subscription;
	loadingComponent;

	public loading: boolean = false;

	constructor(
		private platform: Platform,
		private router: Router,
		private api: ApiService,
		private licenseService: LicenseService,
		public translate: TranslateService,
		private language: LanguageService,
		private socket: WebsocketService,
		private modalService: ModalService,
		private resourceService: ResourceService,
		public authGuard: AuthGuard,
		private render: Renderer2,
		private loadingCtrl: LoadingController,
		private translationService: TranslateService
	) {
		this.initializeApp();
		this.authGuard.loader$.subscribe((loading) => {
			this.loading = loading as boolean;
			console.log("Loader", loading);
		});
	}

	ngOnInit() {}

	initializeApp() {
		this.platform.ready().then(async () => {
			StatusBar.hide();
			setTimeout(() => {
				SplashScreen.hide();
			}, 1000);
			if (this.platform.is("cordova")) {
				const version = (await App.getInfo()).version;
				if (version !== null) {
					this.api.softwareVersion.next(version);
				} else {
					this.api.softwareVersion.next(this.appVersion);
				}
			} else {
				this.api.softwareVersion.next(this.appVersion);
			}
			this.language.setInitLang();

			/*Start resource listening changes*/
			this.resourceService.startResourceListener().subscribe((res: any) => {
				if (res) {
					if (this.licenseService.license.getValue() != null) {
						this.licenseService
							.checkingLicense(this.licenseService.license.getValue())
							.toPromise();
					}
				}
			});

			this.licenseSubscription = this.licenseService.licenseListener().subscribe();

			/*Remove Connector Callback*/
			this.socket.removeConnectorCallback.subscribe((r) => {
				if (r) {
					this.socket.connectionCloseWithReconnect();
				}
			});

			/*Reconnect Socket if undefined status of connection */
			this.socket.socketStatus
				.pipe(
					mergeMap(() => {
						if (!this.reconnectRunning) {
							this.reconnectRunning = true;
							let time = Math.floor(Math.random() * (60 - 5 + 1) + 5);
							time = time * 1000;

							return timer(time).pipe(
								switchMap(() => {
									this.reconnectRunning = false;
									console.log("Socket Connection");

									this.api
										.readDeviceLicense()
										.pipe(take(1))
										.subscribe((li) => {
											this.licenseService.checkingLicense(li).toPromise();
										});

									return this.api.createSocketConnection();
								})
							);
						}
						return of(true);
					})
				)
				.subscribe();

			/*Wipe device Callback */
			this.removeDeviceListener();

			(await this.api.getDeviceInfo()).subscribe();
		});

		this.api.reConnectingStatus$.subscribe((reConnecting) => {
			console.log(reConnecting);
			if (reConnecting) {
				this.showloading();
			} else {
				this.dismissloading();
			}
		});
	}

	async showloading() {
		this.loadingComponent = await this.loadingCtrl.create({
			message: this.translationService.instant("RECONNECTING"),
		});
		this.loadingComponent.present();
	}
	async dismissloading() {
		if (this.loadingComponent) {
			this.loadingComponent.dismiss();
		}
	}

	removeDeviceListener() {
		this.socket.removeCallback.subscribe(() => {
			this.removeDevice();
		});
	}

	async removeDevice() {
		this.licenseSubscription.unsubscribe();
		this.socket.connectionClose();
		this.resourceService.resource.next(undefined);
		this.modalService.isModalOpen().then((v) =>
			v
				? this.modalService
						.dismissModal()
						.then()
						.catch((_) => {})
				: null
		);
		this.api.authenticationState.next(false);

		let serial;
		this.api.getSerial().then((s) => {
			serial = s;
		});

		await this.api.clearStorage().then(() => {
			this.api.saveSerial(serial);
			this.api.deviceId.next(undefined);
			this.router.navigateByUrl("/login");
		});
	}
}
