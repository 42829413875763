export interface SettingsInterface {
	buttonSettings: Array<any>;
	EmployeePIN: string;
	ActivateAdditionalSubjects: boolean;
	KioskMode: boolean;
	AdditionalSubjectsDuration: number;
	NotificationDuration: number;
}

export class Settings implements SettingsInterface {
	buttonSettings;
	ActivateAdditionalSubjects = false;
	KioskMode = false;
	AdditionalSubjectsDuration = 30;
	NotificationDuration = 10;
	EmployeePIN = null;
}

export class AppSettings {
	public static defaultImage = "./assets/img/icon.png";
}

export interface ResourceObject {
	address: {
		city: string;
		countryOrRegion: string;
		postalCode: string;
		state: string;
		street: string;
	};
	addOns: Array<any>;
	connectorId: string;
	language: string;
	devices: Array<any>;
	displayName: string;
	licenseNumber: string;
	organizationId: string;
	resourceAccount: string;
	settings: SettingsInterface;
	buttons: Array<DoorBellButton>;
	_id: string;
	_t: string;
}
export interface DoorBellButton {
	_id: string;
	buttonName: string;
	team: string;
	channel: ChannelData;
	activateEmployeePIN: boolean;
	activatePhoto: boolean;
	activatePhotoPrivacyPolicy: boolean;
	employeePIN: string;
	employeePINSalt: string;
	lockURL: string;
	lockkey: string;
	subjects: DoorBellData;
	successMessage: DoorBellText;
	waitingMessage: DoorBellText;
	failedMessage: DoorBellText;
	infoMessage: DoorBellText;
	privacyPolicy: DoorBellText;
	enableChat: boolean;
	enableCalling: boolean;
	enableConcern: boolean;
	callConnector: string;
	callType: string;
	recipientId: string;
	defaultPostMessage: string;
	defaultTriggerId: string;
}
export class DoorBellData {
	_id: string;
	title: DoorBellText;
	message: DoorBellText;
	triggerId: string;
}

export interface DoorBellMessages {
	successMessage: DoorBellText;
	waitingMessage: DoorBellText;
	failedMessage: DoorBellText;
	infoMessage: DoorBellText;
	privacyPolicy: DoorBellText;
}

export class DoorBellText {
	en: string = "";
	de: string = "";
}
export class ChannelData {
	id: string = "";
	displayname: string = "";
}
export interface iMessageResp {
	id?: string;
	etag?: string;
}
