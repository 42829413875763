import { Component, OnInit, Input } from "@angular/core";
import { IonicModule } from "@ionic/angular";
import { ModalController } from "@ionic/angular";
import { TranslateModule } from "@ngx-translate/core";

@Component({
	selector: "app-success-modal",
	templateUrl: "./success-modal.page.html",
	styleUrls: ["./success-modal.page.scss"],
	imports: [IonicModule, TranslateModule],
})
export class SuccessModalPage implements OnInit {
	@Input() message: string;

	constructor(private mdlCtrl: ModalController) {}

	ngOnInit() {}

	close() {
		this.mdlCtrl.dismiss();
	}
}
