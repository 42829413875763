import { BehaviorSubject, Observable, of, Subscription } from "rxjs";
import { ApiService } from "src/app/services/api/api.service";
import { Injectable } from "@angular/core";
import { catchError, map, switchMap, switchMapTo, take } from "rxjs/operators";
import { WebsocketService } from "./websocket.service";
import { ModalService, ModalType } from "../modal-service/modal.service";
import { ResourceService } from "../resources/resource.service";
import { ResourceObject, Settings } from "src/app/interfaces/interfaces";

@Injectable({
	providedIn: "root",
})
export class LicenseService {
	private licenseObj: License;
	private timeout: any;
	public license: BehaviorSubject<License> = new BehaviorSubject<License>(null);
	public isInit = true;
	public subscription: Subscription = new Subscription();
	constructor(
		private api: ApiService,
		private socket: WebsocketService,
		private modalService: ModalService,
		private resourceService: ResourceService
	) {}

	licenseListener() {
		return this.socket.licenseCallback.pipe(
			switchMap(() => {
				if (this.license.getValue() === undefined) {
					this.modalService
						.isModalOpen()
						.then((v) => (v ? this.modalService.dismissModal() : null));
				}
				console.log("licenseListener");

				return this.api.readDeviceLicense().pipe(
					switchMap((license) => {
						return this.checkingLicense(license).pipe();
					}),
					catchError((err) => {
						this.modalService.showModal(ModalType.NOLICENSE).catch((_) => {});
						this.license.next(undefined);
						return of(false);
					})
				);
			})
		);
	}

	checkingLicense(li): Observable<any> {
		if (li.status === 404) {
			this.modalService.showModal(ModalType.NOLICENSE).catch((_) => {});
			this.license.next(undefined);
			return of(false);
		}
		this.setLicense(li);
		this.check_demo();
		// Bug: When the default check happens this closes the Call modal
		//this.modalService.dismissModal();
		return of(true);
	}
	public check_demo() {
		if (this.isFree()) {
			const sett = new Settings();
			this.resourceService.resourceSettings.next(sett);
		}

		if (this.isValid() && !this.isFree()) {
			this.api
				.getResource()
				.pipe()
				.toPromise()
				.then((r: ResourceObject) => {
					this.resourceService.resourceSettings.next(r.settings);
				});
		}
	}

	public setLicense(li: License) {
		this.licenseObj = li;
		this.license.next(li);
	}

	public isValid() {
		if (this.licenseObj != null) {
			return true;
		}
		return false;
	}

	public isTrial() {
		if (this.licenseObj.licenseType === "Trial7Days") {
			return true;
		}
		return false;
	}

	public isFree() {
		if (this.licenseObj != null && this.licenseObj.licenseType === "Free") {
			return true;
		}
		return false;
	}
}
export interface License {
	_id: string;
	userId: string;
	type: string;
	licenseType: string;
	licenseNumber: string;
	deviceId: string;
	activateDate: string;
	expireDate: string;
	tokenId: string;
	status: number;
}
