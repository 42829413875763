import { Component, OnInit, Input } from "@angular/core";
import { IonicModule } from "@ionic/angular";
import { ModalController } from "@ionic/angular";
import { TranslateModule } from "@ngx-translate/core";
import { QRCodeComponent } from "angularx-qrcode";
import { ConfiguredMessageService } from "src/app/services/configured-message/configured-message.service";
import { LanguageService } from "src/app/services/language-service/language.service";

@Component({
	selector: "app-privacy-policy-modal",
	templateUrl: "./privacy-policy-modal.page.html",
	styleUrls: ["./privacy-policy-modal.page.scss"],
	imports: [IonicModule, TranslateModule, QRCodeComponent],
})
export class PrivacyPolicyModalPage implements OnInit {
	@Input() message: string;
	@Input() header: string;
	@Input() url: string = "https://nettask.de";
	constructor(private mdlCtrl: ModalController) {}

	ngOnInit() {}

	close(accepted: boolean) {
		this.mdlCtrl.dismiss(accepted);
	}
}
