import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "./guards/auth.guard";
import { CommonModule } from "@angular/common";
import { environment } from "src/environments/environment";

export const routes: Routes = [
	{
		path: "",
		redirectTo: "dashboard",
		pathMatch: "full",
	},
	{
		path: "dashboard",
		canActivate: [AuthGuard],
		loadComponent: () =>
			import("../app/pages/dashboard/dashboard.page").then((m) => m.DashboardPage),
	},
	{
		path: "register",
		loadComponent: () =>
			import("../app/pages/register/register.page").then((m) => m.RegisterPage),
	},
	{
		path: "login",
		loadComponent: () => import("../app/pages/login/login.page").then((m) => m.LoginPage),
	},
	{
		path: "information",
		//canActivate: [AuthGuard],
		loadComponent: () =>
			import("../app/pages/information/information.page").then((m) => m.InformationPage),
	},
	...(environment.isHXADevice
		? [
				{
					path: "wifi",
					loadComponent: () =>
						import("./pages/wifi-configuration/wifi-configuration.page").then(
							(m) => m.WifiConfigurationPage
						),
				},
		  ]
		: []),
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { useHash: true }), CommonModule],
	exports: [RouterModule],
})
export class AppRoutingModule {}
