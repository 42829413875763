import { LicenseService } from "./../services/api/license.service";
import { Injectable } from "@angular/core";
import { ApiService } from "./../services/api/api.service";
import { CanActivate, Router } from "@angular/router";
import { catchError, switchMap } from "rxjs/operators";
import { of, throwError, Subject, BehaviorSubject } from "rxjs";

import { ModalMessages, ModalService, ModalType } from "../services/modal-service/modal.service";
import { ResourceService } from "../services/resources/resource.service";

import { TranslateService } from "@ngx-translate/core";
import { WebsocketService } from "../services/api/websocket.service";
import { ResourceObject } from "../interfaces/interfaces";

@Injectable({
	providedIn: "root",
})
export class AuthGuard implements CanActivate {
	theme;
	loader$ = new Subject();
	public startNavigation = new BehaviorSubject<string>("");
	constructor(
		private auth: ApiService,
		private licenseService: LicenseService,
		private router: Router,
		private modalService: ModalService,
		private resourceService: ResourceService,
		private translate: TranslateService,
		private socket: WebsocketService
	) {
		this.loader$.next(false);
	}

	canActivate(route, state) {
		console.log("canActivate");
		console.log(this.auth.authenticationState.getValue());

		this.loader$.next(true);
		this.startNavigation.next("start");
		// if (!this.auth.authenticationState.getValue()) {
		return this.auth.getResource().pipe(
			switchMap((resource: ResourceObject) => {
				this.resourceService.checkResource(resource);
				return this.resourceService.checkButtons(resource).pipe(
					switchMap((p) => {
						console.log("Buttons", p);

						if (!p) {
							const a: ModalMessages = {
								message: this.translate.instant("MODAL.DEFAULTMESSAGE.NOBUTTON"),
								header: "",
							};
							this.modalService.showModal(ModalType.ERROR, a);
							this.startNavigation.next("end");
							this.loader$.next(false);
							this.router.navigate(["login"]);
							return throwError(false);
						} else {
							console.log("ConnectoreID", resource.connectorId);

							if (resource.connectorId == null) {
								const a: ModalMessages = {
									message: this.translate.instant(
										"MODAL.DEFAULTMESSAGE.NOCONNECTOR"
									),
									header: "",
								};
								this.modalService.showModal(ModalType.ERROR, a);
								this.startNavigation.next("end");
								this.loader$.next(false);
								this.router.navigate(["login"]);
								return throwError(false);
							}

							this.loader$.next(false);
							this.modalService.dismissModal();
						}
						if (resource.licenseNumber == null) {
							const a: ModalMessages = {
								message: this.translate.instant(
									"MODAL.DEFAULTMESSAGE.LICENSECHECK"
								),
								header: "",
							};
							this.modalService.showModal(ModalType.ERROR, a);
							this.startNavigation.next("end");
							this.loader$.next(false);
							this.router.navigate(["login"]);
							return of(false);
						}
						return this.auth.createSocketConnection().pipe(
							switchMap(() => {
								const deviceModel = {
									HardwareId: this.auth.deviceId.getValue(),
									AppVersion: this.auth.softwareVersion.getValue(),
								};
								this.auth
									.putDevice(deviceModel)
									.toPromise()
									.then()
									.finally()
									.catch((err) => {
										this.loader$.next(false);
										return throwError(err);
									});
								this.loader$.next(false);
								this.auth.authenticationState.next(true);
								return this.auth.readDeviceLicense().pipe(
									switchMap((li) => {
										this.licenseService.setLicense(li);
										const license = li;
										if (license.status === 404) {
											const a: ModalMessages = {
												message: this.translate.instant(
													"MODAL.DEFAULTMESSAGE.LICENSECHECK"
												),
												header: "",
											};
											this.modalService.showModal(ModalType.ERROR, a);
											this.auth.authenticationState.next(false);
											this.socket.connectionClose();
											this.licenseService.setLicense(null);
											this.router.navigate(["login"]);
											this.loader$.next(false);
											this.startNavigation.next("end");
											return of(false);
										}
										this.loader$.next(false);
										this.startNavigation.next("end");
										return of(true);
									}),
									catchError((err) => {
										this.loader$.next(false);
										return throwError(err);
									})
								);
							}),
							catchError((err) => {
								this.loader$.next(false);
								return throwError(err);
							})
						);
					}),
					catchError((err) => {
						this.loader$.next(false);
						return throwError(err);
					})
				);
			}),
			catchError((err) => {
				this.auth.authenticationState.next(false);
				this.loader$.next(false);
				this.socket.connectionClose();
				this.router.navigate(["login"]);
				this.startNavigation.next("end");
				return of(false);
			})
		);
		// } else {
		//   console.log("Else canActivate");

		//   this.socket.licenseCallback.next(true);
		//   this.loader$.next(false);
		//   return true;
		// }
	}

	// return this.auth.readDeviceLicense().pipe(
	//   switchMap(li => {
	//     const license = li;
	//
	//     if (license.status == 404) {
	//       this.modalService.showModal(ModalType.NOLICENSE);
	//       return of(false);
	//     }
	//   }));

	/*canActivate() {
    if (!this.licenseService.isValid() || this.auth.relogin) {
      this.auth.relogin = false;
      return this.auth.readDeviceLicense().pipe(
        take(1),
        switchMap(
          (li: License) => {
            if (li) {
              this.auth.authenticationState.next(true);
              this.licenseService.setLicense(li);
              return this.getCorrectSettings().pipe(
                switchMap(s => {
                  this.theme = s.theme;
                  return this.storageService.getAccount().pipe(
                    switchMap(x => {
                      if (x) {
                        this.ews.init.next(true);
                        return of(this.auth.authenticationState);
                      }
                      return of(this.auth.authenticationState);
                    })
                  );
                })
              );
            } else {
              this.router.navigate(['login']);
              return of(false);
            }
          }
        ),
        switchMap(() => {
          return this.storageService.getSerialNumber().pipe(switchMap(res => {

            if (res !== null) {

              return of(true);
            }

            return this.auth.getDeviceInfo().pipe(switchMap(d => {
              if (d) {
                const deviceModel = {
                  serialNumber: d.serial
                };

                return this.auth.putDevice(deviceModel).pipe();
              }
            }));

          }));
        }),
        switchMap(() => {
          const deviceModel = {
            appVersion: this.auth.softwareVersion.getValue()
          };
          return this.auth.putDevice(deviceModel).pipe();
        }),
        switchMap(() => {
          return this.auth.createSocketConnection().pipe(
            tap(() => {
              if (this.ews.isAuthenticated) {
                this.router.navigateByUrl(this.theme);
              } else {
                //this.router.navigate(['service-account-selector']);
              }
            }),
          );
        }),
        catchError((err) => {
          if (err.status === 404 || err.status === 400) {
            const msg: ModalMessages = { message: err.error.error };
            this.modalService.showModal(ModalType.ERROR, msg);
          }
          this.router.navigate(['login']);
          return of(false);
        }
        ));
    } else if (!this.storageService.isEWSLoggedIn()) {
      this.router.navigate(['service-account-selector']);
      return false;
    } else {
      return true;
    }
  }*/

	// checkifDevice(resource: ResourceObject) {
	//     const device = resource.devices.find(d => d.hardwareId === this.auth.deviceId.getValue());
	//     if (device !== null || device !== undefined) {
	//       return of(true);
	//     } else {
	//       return of(false);

	//     }
	// }

	// getCorrectSettings() {
	//   if (this.licenseService.isFree()) {
	//     const sett = new Settings();
	//     this.storageService.settings.next(sett);
	//     return of(sett);
	//   } else {
	//     return this.storageService.getSetting().pipe(
	//       switchMap(s => {
	//         if (s) {

	//         }
	//         return of(s);
	//       })
	//     );
	//   }
	// }

	// canLoad(): boolean {
	//   if (this.auth.isAuthenticated()) {
	//     return this.auth.isAuthenticated();
	//   } else {
	//     this.router.navigate(['/login']);
	//     return false;
	//   }
	// }
}
