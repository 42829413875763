import { Component, OnInit, Input } from "@angular/core";
import { IonicModule } from "@ionic/angular";
import { ModalController } from "@ionic/angular";
import { TranslateModule } from "@ngx-translate/core";

@Component({
	selector: "app-conditional-modal",
	templateUrl: "./conditional-modal.page.html",
	styleUrls: ["./conditional-modal.page.scss"],
	imports: [IonicModule, TranslateModule],
})
export class ConditionalModalPage implements OnInit {
	@Input() message: string;
	@Input() header: string;

	constructor(private mdlCtrl: ModalController) {}

	ngOnInit() {}

	close(ev: boolean) {
		this.mdlCtrl.dismiss(ev);
	}
}
