import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Network } from '@capacitor/network';
import { BehaviorSubject, fromEvent } from 'rxjs';

export enum ConnStatus {
  Online,
  Offline
}

@Injectable({
  providedIn: 'root',
})
export class NetworkserviceService {

  public status: BehaviorSubject<ConnStatus> = new BehaviorSubject(ConnStatus.Offline);
  constructor(private platform: Platform) {
    this.platform.ready().then(async () => {
      const networkType = (await Network.getStatus()).connectionType
      if (this.platform.is('hybrid') === true) {

        this.checkNetwork();
        const status = networkType !== 'none' ? ConnStatus.Online : ConnStatus.Offline;
        this.status.next(status);
      } else {

        this.browserNetwork();
        const status =  networkType !== 'none' ? ConnStatus.Online : ConnStatus.Offline;
        this.status.next(status);
      }
    });
  }



  public checkNetwork() {
    Network.addListener('networkStatusChange', (status) => {
      if (status.connected) {
        this.updateStatus(ConnStatus.Online);
      } else {
        this.updateStatus(ConnStatus.Offline);
      }
    });
  }

  updateStatus(status: ConnStatus) {
    this.status.next(status);
  }
  
  browserNetwork() {
    fromEvent(window, 'offline').subscribe(() => {
      this.updateStatus(ConnStatus.Offline);
    });
  
    fromEvent(window, 'online').subscribe(() => {
      this.updateStatus(ConnStatus.Online);
    });
  }


  // AI GENERATED: START
async getLocalIPAddress(): Promise<string | null> {
  return new Promise((resolve, reject) => {
    const peerConnection = new RTCPeerConnection({ iceServers: [] });
    peerConnection.createDataChannel('');
    peerConnection.onicecandidate = (event) => {
      if (event.candidate && event.candidate.candidate) {
        const candidate = event.candidate.candidate;
        const ipMatch = candidate.match(
          /(?:\d{1,3}\.){3}\d{1,3}/
        );
        if (ipMatch) {
          resolve(ipMatch[0]);
          peerConnection.close();
        }
      } else if (!event.candidate) {
        resolve(null);
        peerConnection.close();
      }
    };
    peerConnection.createOffer()
      .then((offer) => peerConnection.setLocalDescription(offer))
      .catch((error) => reject(error));
  });
}
// AI GENERATED: END

}